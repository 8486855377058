import * as React from "react";
import {
  Col,
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Box, Button, Container, Grow, Icon, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
} from "../component_library/paragraph";
import { Image } from "../component_library/image";
import { ContactInfoSection } from "../components/contact_info_section";
import { toHTML } from "../component_library/paragraph";
import { useState } from "react";
import { Localization } from "../component_library/localization";
import CloseIcon from "@mui/icons-material/Close";
import { JarsickWebsiteButton } from "../components/jarsick_website_button";
import { useNavigate } from "react-router-dom";
import { NewsSection } from "../components/news_section";
import { Helmet } from "react-helmet";
import { IMAGE_PUBLIC_PATH } from "../constants";
import { GIF_PUBLIC_PATH } from "../constants";
import { DisasterBlasterItchioButton, DisasterBlasterSteamButton } from "../components/social_buttons";
import { Height, MarginTwoTone } from "@mui/icons-material";

function BannerSection() {
  const theme = useTheme();

  const backgroundImageSrcHorizontal = `${IMAGE_PUBLIC_PATH}/db_banner3.png`;
  const backgroundImageSrcVertical = `${IMAGE_PUBLIC_PATH}/db_banner_verticale.png`;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const backgroundImageSrc = isSmallScreen ? backgroundImageSrcVertical : backgroundImageSrcHorizontal;

  return (
    <Box sx={{
      marginTop: "5rem",
      height: "100vh",
      backgroundImage: `linear-gradient(to bottom, rgba(18, 9, 28, 0) 70%, #12091C), url(${backgroundImageSrc})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: "#12091C",
      position: 'relative',

    }}>
      <Row spacing={1} weights={[6, 6]} sx={{ position: 'absolute', top: '40%', width: '100%' }} >
        <></>
        <Col
          sx={{
            px: "2rem",
            py: "1rem",
            backgroundColor: isSmallScreen ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.5)",
            borderRadius: "2rem",
            width: "60%",

          }}
          weights={[8, 4]}
          spacing={2}>
          <Paragraph>
            <ParagraphBody center={true}>
              {toHTML(Localization.get("disaster_blaster_short_description"))}
            </ParagraphBody>
          </Paragraph>
          <JarsickWebsiteButton
            onClick={() => navigate("/disasterblaster")}
          >
            {Localization.get("learn_more_button")}
          </JarsickWebsiteButton>
        </Col>
      </Row>
    </Box>
  );

}


function GameInEvidenceSection() {
  const theme = useTheme();
  const backgroundImageSrc = `${IMAGE_PUBLIC_PATH}/homepage_db_background_end.jpg`;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const backgroundColor = "#72499E";


  return (
    <ContentPanel color={backgroundColor}>
      <Row>
        <Paragraph>
          <ParagraphTitle center={true}>
            {toHTML(Localization.get("demo_try_call_to_action_title"))}
          </ParagraphTitle>
          <ParagraphBody center={true}>
            {toHTML(Localization.get("demo_try_call_to_action"))}
          </ParagraphBody>
        </Paragraph>
      </Row>
      <Row>
        <DisasterBlasterSteamButton sx={{ width: "10rem" }} />
      </Row>
    </ContentPanel >
  );
}

function AboutUsSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const backgroundColor = "#0f0716"
  const backgroundImageSrc = `${IMAGE_PUBLIC_PATH}/homepage_db_background_end.jpg`;
  return (
    <div id="about">
      <ContentPanel color={backgroundColor} backgroundImageSrc={backgroundImageSrc}>
        <ContentPanelTitle> {Localization.get("about_us_section_title")} </ContentPanelTitle>
        <Row>
          <Paragraph>
            <ParagraphBody>
              {Localization.get("gamedev_studio_description")}
            </ParagraphBody>
          </Paragraph>
        </Row>
        <Row>
          <Image src={`${IMAGE_PUBLIC_PATH}/the-team-c.png`} />
          <Image src={`${IMAGE_PUBLIC_PATH}/the-team-d.png`} />
          <Image src={`${IMAGE_PUBLIC_PATH}/the-team-s.png`} />
        </Row>
      </ContentPanel>
    </div>
  );
}


export function HomePage() {
  return (
    <>
      <Helmet>
        <title>Jarsick</title>
        <meta name="description" content="Jarsick is an independent video game development studio based in Turin, Italy. We are now developing Disaster Blaster!" />
        <link rel="canonical" href="https://www.jarsick.com" />
      </Helmet>
      <BannerSection />
      <AboutUsSection />
      <NewsSection backgroundColor={"#0f0716"} />
      <ContactInfoSection />
    </>
  );
}
