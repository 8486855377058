import { Box, Grow, IconButton, Modal, useMediaQuery } from "@mui/material";
import { Col, ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { ContactInfoSection } from "../components/contact_info_section";
import { Image } from "../component_library/image";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { Carousel } from "../component_library/carousel";
import { NewsSection } from "../components/news_section";
import { Helmet } from "react-helmet";
import { JarsickWebsiteButton, JarsickWebsiteIconButton } from "../components/jarsick_website_button";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { SpatialTracking } from "@mui/icons-material";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { DisasterBlasterSteamButton, InstagramButton, TikTokButton, XButton, YouTubeButton } from "../components/social_buttons";
import GroupsIcon from '@mui/icons-material/Groups';
import { GIF_PUBLIC_PATH } from "../constants";
import { IMAGE_PUBLIC_PATH } from "../constants";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
function DisasterBlasterParagraphTitle({ textColor = "white", borderColor = "white", backgroundColor = "black", children }) {

    return (
        <Box sx={{ marginBottom: "2rem", }}>
            <ParagraphTitle
                color={textColor}
                center={true}
                sx={{
                    border: "0.2rem solid #fff",
                    borderRadius: "1rem",
                    padding: "0.4em",
                    boxShadow: `0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 1rem ${borderColor}, 0 0 0.4rem ${borderColor}, 0 0 1.4rem ${borderColor}, inset 0 0 0.7rem ${borderColor}`,
                    textShadow: `0 0 .2rem ${textColor}`,
                    backgroundColor: `${backgroundColor}`
                }}>
                {children}
            </ParagraphTitle>
        </Box>
    );
}

function TrailerSection() {

    const backgroundImageSrcHorizontal = `${IMAGE_PUBLIC_PATH}/db_banner3.png`;
    const backgroundImageSrcVertical = `${IMAGE_PUBLIC_PATH}/db_banner_verticale.png`;
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const backgroundImageSrc = isSmallScreen ? backgroundImageSrcVertical : backgroundImageSrcHorizontal;
    const padding = isSmallScreen ? "10rem" : "15rem";

    return (
        <Box sx={{
            marginTop: "5rem",
            height: "100vh",
            backgroundImage: `linear-gradient(to bottom, rgba(18, 9, 28, 0) 70%, #12091C), url(${backgroundImageSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: "#12091C",
            position: 'relative',

        }}>
            <Row spacing={1} weights={[6, 6]} sx={{ position: 'absolute', top: '40%', width: '100%' }} >
                <></>
                <Grow in={true} timeout={1000}>
                    <iframe
                        width="80%"
                        height="360px"
                        src="https://www.youtube.com/embed/uYrcHL6srGM?si=XfVrxBrs1XNH0SNa"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                    >
                    </iframe>
                </Grow>
            </Row>

        </Box>
    );
}


function DisasterBlasterFeaturePanel({ backgroundColor, title, body, imageSrc, switchImageAndText = false }) {

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const rowSpacing = 9;
    const rowBorderRadius = "1rem";
    const rowBoxShadow = "0 0 1rem #000, 0 0 2rem #000";
    const rowPadding = isSmallScreen ? "0rem" : "2rem";

    const titleBorderColor = "#fffA8f";
    const titleColor = "#ffffd4";
    const titleBackgroundColor = "#553676";

    const imageBorderRadius = "2rem";
    const imageBoxShadow = `0 0 1rem ${titleColor}, 0 0 2rem ${titleColor}`

    return (

        switchImageAndText ? (
            <Row
                sx={{
                    backgroundColor: backgroundColor,
                    borderRadius: rowBorderRadius,
                    boxShadow: rowBoxShadow,
                    padding: rowPadding,
                }}
            >
                <div>
                    <Image
                        src={imageSrc}
                        sx={{
                            borderRadius: imageBorderRadius,
                            boxShadow: imageBoxShadow,
                        }}
                    />
                </div>
                <Paragraph>
                    <DisasterBlasterParagraphTitle
                        borderColor={titleBorderColor}
                        textColor={titleColor}
                        backgroundColor={titleBackgroundColor}
                    >
                        {title}
                    </DisasterBlasterParagraphTitle>
                    <ParagraphBody center={isSmallScreen}>
                        {body}
                    </ParagraphBody>
                </Paragraph>
            </Row>
        ) : (
            <Row
                sx={{
                    backgroundColor: backgroundColor,
                    borderRadius: rowBorderRadius,
                    boxShadow: rowBoxShadow,
                    padding: rowPadding,
                }}
            >
                <Paragraph>
                    <DisasterBlasterParagraphTitle
                        borderColor={titleBorderColor}
                        textColor={titleColor}
                        backgroundColor={titleBackgroundColor}
                    >
                        {title}
                    </DisasterBlasterParagraphTitle>
                    <ParagraphBody center={isSmallScreen}>
                        {body}
                    </ParagraphBody>
                </Paragraph>
                <div>
                    <Image
                        src={imageSrc}
                        sx={{
                            borderRadius: imageBorderRadius,
                            boxShadow: imageBoxShadow,
                        }}
                    />
                </div>
            </Row>
        )
    );
}


function AboutGameSection() {

    const panelBackgroundColor = "#211132";
    const textBackgroundColor1 = "#72499f";
    const textBackgroundColor2 = "#a16bd5";
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const SCREENSHOT_COUNT = 9;
    const theme = useTheme();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = Array.from({ length: SCREENSHOT_COUNT }, (_, i) =>
        `${IMAGE_PUBLIC_PATH}/disasterblaster_ss${i + 1}.jpg`
    );

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const goToNextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % SCREENSHOT_COUNT);
    };

    return (
        <>
            <div>
                <ContentPanel color="#12091C">
                    <Row>
                        <Carousel timeInterval={5000}>
                            {images.map((src, index) => (
                                <Box onClick={() => openModal(index)} sx={{ cursor: "pointer" }}>
                                    <Image src={src} />
                                </Box>
                            ))}
                        </Carousel>
                    </Row>
                </ContentPanel>

                {isModalOpen && (
                    <Modal open={isModalOpen}>
                        <Box onClick={goToNextImage} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: "100%",
                            height: "100%",
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        }}>
                            <Image src={images[currentImageIndex]} width={isSmallScreen ? "100%" : "80%"} />
                            <IconButton
                                onClick={closeModal}
                                sx={{
                                    color: "white",
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Modal>
                )}
            </div>

            <ContentPanel backgroundImageSrc={`${IMAGE_PUBLIC_PATH}/db_panel_gradient_background.jpg`}>
                <Row>
                    <Paragraph>
                        <ParagraphTitle>
                            {toHTML(Localization.get("disaster_blaster_catchphrase"))}
                        </ParagraphTitle>
                        <ParagraphBody>
                            {toHTML(Localization.get("disaster_blaster_description"))}
                        </ParagraphBody>
                    </Paragraph>
                </Row>
                <Row>
                    <Paragraph>
                        <ParagraphTitle center={true}>
                            {toHTML(Localization.get("demo_try_call_to_action_title"))}
                        </ParagraphTitle>
                        <ParagraphBody center={true}>
                            {toHTML(Localization.get("demo_try_call_to_action"))}
                        </ParagraphBody>
                    </Paragraph>
                </Row>
                <Row>
                    <DisasterBlasterSteamButton sx={{ width: "10rem" }} />
                </Row>


            </ContentPanel>

            <ContentPanel color={panelBackgroundColor}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor1}
                    title={Localization.get("disaster_blaster_build_title")}
                    body={Localization.get("disaster_blaster_build_description")}
                    imageSrc={`${GIF_PUBLIC_PATH}/db-store-build.gif`}
                />
            </ContentPanel>

            <ContentPanel backgroundImageSrc={`${IMAGE_PUBLIC_PATH}/db_panel_background.jpg`}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor2}
                    title={Localization.get("disaster_blaster_destroy_title")}
                    body={Localization.get("disaster_blaster_destroy_description")}
                    imageSrc={`${GIF_PUBLIC_PATH}/db-store-destroy.gif`}
                    switchImageAndText={true}
                />
            </ContentPanel>

            <ContentPanel color={panelBackgroundColor}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor1}
                    title={Localization.get("disaster_blaster_discover_title")}
                    body={Localization.get("disaster_blaster_discover_description")}
                    imageSrc={`${GIF_PUBLIC_PATH}/db-store-discover.gif`}
                />
            </ContentPanel >

            <ContentPanel backgroundImageSrc={`${IMAGE_PUBLIC_PATH}/db_panel_background.jpg`}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor2}
                    title={Localization.get("disaster_blaster_upgrade_title")}
                    body={Localization.get("disaster_blaster_upgrade_description")}
                    imageSrc={`${GIF_PUBLIC_PATH}/db-store-upgrade.gif`}
                    switchImageAndText={true}
                />
            </ContentPanel>
        </>
    );
}

function CommunitySection() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const socialButtonsWidth = "4rem";
    const socialButtonsMargin = isSmallScreen ? "0rem" : "1rem";

    return (
        <ContentPanel backgroundImageSrc={`${IMAGE_PUBLIC_PATH}/db_stars_background.jpg`}>
            <ContentPanelTitle>
                {Localization.get("join_community_title")}
            </ContentPanelTitle>
            <Row>
                <Paragraph>
                    <ParagraphBody>
                        {Localization.get("join_community_description")}
                    </ParagraphBody>
                </Paragraph>
            </Row>
            <Row>

                <Col weights={[5, 5, 2]} sx={{ px: "2rem" }} spacing={2}>
                    <AutoStoriesIcon sx={{ fontSize: 100, color: "white" }} />
                    <Paragraph>
                        <ParagraphBody center={true}>
                            {Localization.get("wiki_description")}
                        </ParagraphBody>
                    </Paragraph>
                    <JarsickWebsiteButton
                        onClick={() => navigate("/disasterblaster/wiki")}
                        color={theme.palette.primary.main}
                    >
                        {Localization.get("wiki_button")}
                    </JarsickWebsiteButton>
                </Col>
                <Col weights={[6, 6]}>
                    <GroupsIcon sx={{ fontSize: 100, color: "white" }} />
                    <Paragraph>
                        <ParagraphBody>
                            <TikTokButton sx={{ width: socialButtonsWidth, mr: socialButtonsMargin }} />
                            <YouTubeButton sx={{ width: socialButtonsWidth, mr: socialButtonsMargin }} />
                            <XButton sx={{ width: socialButtonsWidth, mr: socialButtonsMargin }} />
                            <InstagramButton sx={{ width: socialButtonsWidth, mr: socialButtonsMargin }} />
                        </ParagraphBody>
                    </Paragraph>
                    <></>
                </Col>
            </Row>
        </ContentPanel>
    );
}



export function DisasterBlasterPage() {
    return (
        <div>
            <Helmet>
                <title>Disaster Blaster</title>
                <meta name="description" content="Disaster Blaster is a roguelite card game where you build your own automatic weapon with crazy upgrading cards to decimate waves of enemies and create the most devastating arsenal in the universe. Increase damage, multiply bullets, make them explosive and electrifying, it's all up to you!" />
                <link rel="canonical" href="https://www.jarsick.com/disasterblaster" />
            </Helmet>
            <TrailerSection />
            <AboutGameSection />
            <CommunitySection />
            <NewsSection backgroundColor={"#211233"} />
            <ContactInfoSection />
        </div>
    );
}
